.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@font-face {
  font-family: "Proxima Nova";
  src: local('ProximaNova'), url('./Assets/Fonts/ProximaNova-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local('ProximaNova'), url('./Assets/Fonts/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local('ProximaNova'), url('./Assets/Fonts/ProximaNova-Reg.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local('ProximaNova'), url('./Assets/Fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local('ProximaNova'), url('./Assets/Fonts/ProximaNova-Sbold.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
}

* {
  font-size: 100%;
  font-family: 'Proxima Nova', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}


.inner-shadow-custom {
  -webkit-box-shadow: inset 0px 10px 30px -25px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px 10px 30px -25px rgba(0,0,0,1);
  box-shadow: inset 0px 10px 30px -25px rgba(0,0,0,1);
}